import type { AppProps } from 'next/app'
import { useEffect, useState } from 'react'
import { NextIntlClientProvider } from 'next-intl'
import '../styles/globals.css'
import { init } from '@socialgouv/matomo-next'
import { getCookie } from '../utils/cookie/get_cookie'
import { AppContext } from '../utils/context'
import { useRouter } from 'next/router'
import { Locale } from '../types/shared_types'
import { ConsentDataType, IubendaCookieType } from '../types/consent'

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const locale = (router.locale || 'en') as Locale
  const pathName = router.asPath.split('?')[0]

  const [consentData, setConsentData] = useState<ConsentDataType | undefined>(
    undefined
  )

  useEffect(() => {
    // Get the iubenda cookie value and save in the purpose
    if (typeof document !== 'undefined') {
      const path = document.location.pathname
      const matomoSiteId = path.includes('tonnenheld')
        ? process.env.NEXT_PUBLIC_MATOMO_SITE_ID_TONNENHELD
        : process.env.NEXT_PUBLIC_MATOMO_SITE_ID

      const iubendaId =
        locale === 'de'
          ? process.env.NEXT_PUBLIC_IUBENDA_POLICYID_DE
          : process.env.NEXT_PUBLIC_IUBENDA_POLICYID_EN

      const consentCookie = getCookie(`_iub_cs-${iubendaId}`) as
        | IubendaCookieType
        | undefined

      setConsentData(consentCookie?.purposes)

      if (MATOMO_URL && matomoSiteId) {
        const initOptions = {
          url: MATOMO_URL,
          siteId: matomoSiteId,
          disableCookies: consentCookie?.purposes[4] ? false : true
        }

        init(initOptions)
      }
    }
  }, [])

  return (
    <AppContext.Provider value={{ consentData, setConsentData }}>
      <NextIntlClientProvider
        locale={router.locale}
        timeZone="Europe/Berlin"
        messages={pageProps.messages}
      >
        <Component {...pageProps} key={pathName + router.locale} />
      </NextIntlClientProvider>
    </AppContext.Provider>
  )
}

export default App
