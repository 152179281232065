import { createContext } from 'react'
import { ConsentDataType } from '../../types/consent'

export interface AppContextType {
  consentData: ConsentDataType | undefined
  setConsentData: React.Dispatch<
    React.SetStateAction<ConsentDataType | undefined>
  >
}

export const AppContext = createContext<AppContextType | undefined>(undefined)
